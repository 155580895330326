:root {
    --primary: #2E7BBF;
    --GRAY-200: #EAECF0;
}

main {
    font-family: 'Inter', sans-serif;
}

.w-full {
    width: 100%;
}

.px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
}

.m-0 {
    margin: 0;
}

.slick-dots {
    padding-top: 4px;
}

.slick-dots li {
    width: 8px;
    height: 8px;
    margin: 0 4px;
}

.slick-dots li button {
    width: 100%;
    height: 100%;
}

.slick-dots li button::before {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 9999px;
    background-color: var(--GRAY-200); 
    opacity: 1;
}

.slick-dots li.slick-active {
    width: 24px;
}

.slick-dots li.slick-active button::before {
    background-color: var(--primary); 
}

.cms-container .content img {
    max-width: 100%;
}

.cms-page-content img {
    max-width: 100%;
}

.cms-container .content iframe,
.cms-page-content iframe,
.custom-blog-detail iframe {
    max-width: 100%;
}

.cms-page-content p,
.cms-page-content ul li {
    font-size: 14px;
}

#ymDivBar {
    z-index: 1200 !important;
}

#ymDivCircle {
    z-index: 1199 !important;
}


@media screen and (max-width: 768px) {
    .cms-page-content img {
        width: 100%;
        height: auto;
    }

    .cms-container .content img {
        width: 100%;
        height: auto;
    }

    .cms-container .content iframe,
    .custom-blog-detail iframe {
        height: 100%;
        min-height: 250px;
    }
    
    .cms-page-content iframe {
        height: 100%;
        min-height: 250px;
    }

    #ymDivBar {
        display: none !important;
    }
}